@font-face {
  font-family: TeX Gyre Heros Cn;
  src: local(TeX Gyre Heros Cn), url("texgyreheroscn-regular.afa1bb81.woff2") format("woff2");
  font-stretch: condensed;
}

body {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  padding-block: 65px;
  padding-inline: 25px;
  font-family: TeX Gyre Heros Cn;
  font-size: 26px;
  font-stretch: condensed;
  line-height: 29.9px;
}

@media (width > 430px) {
  body {
    padding-block: calc(2.40296vw + 6.66728px);
    padding-inline: calc(2.31054vw + 15.0647px);
    font-size: calc(1.2939vw + 20.436px);
    line-height: calc(1.48799vw + 23.5017px);
  }
}

@media (width >= 1512px) {
  body {
    padding-block: 43px;
    padding-inline: 50px;
    font-size: 40px;
    line-height: 46px;
  }
}

main {
  text-wrap: balance;
}

ul, p {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (width > 1024px) {
  ul {
    display: contents;
  }

  li {
    display: inline;
  }

  li:not(:first-of-type):before {
    content: " / ";
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  text-underline-offset: 8%;
  text-decoration-skip-ink: none;
  text-decoration: underline .05em;
}

a:focus-visible {
  outline: none;
}

::selection {
  color: #000;
  background-color: #fff;
}
/*# sourceMappingURL=index.4aa4d7b9.css.map */
