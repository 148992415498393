@font-face {
  font-family: "TeX Gyre Heros Cn";
  src: local("TeX Gyre Heros Cn"),
    url("./texgyreheroscn-regular.woff2") format(woff2);
  font-stretch: condensed;
}

body {
  background-color: black;
  color: white;
  padding-inline: 25px;
  padding-block: 65px;
  font-family: "TeX Gyre Heros Cn";
  font-stretch: condensed;
  font-size: 26px;
  line-height: 29.9px;
  -webkit-font-smoothing: antialiased;
}

@media (width > 430px) {
  body {
    font-size: calc(1.2939vw + 20.436px);
    line-height: calc(1.487985vw + 23.50166px);
    padding-block: calc(2.402957vw + 6.66728px);
    padding-inline: calc(2.310536vw + 15.0647px);
  }
}

@media (width >= 1512px) {
  body {
    font-size: 40px;
    line-height: 46px;
    padding-block: 43px;
    padding-inline: 50px;
  }
}

main {
  text-wrap: balance;
}

ul,
p {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (width > 1024px) {
  ul {
    display: contents;
  }
  li {
    display: inline;
  }
  li:not(:first-of-type)::before {
    content: " / ";
  }
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: underline;
  text-decoration-thickness: 0.05em;
  text-underline-offset: 8%;
  text-decoration-skip-ink: none;
}

a:focus-visible {
  outline: none;
}

::selection {
  background-color: white;
  color: black;
}
